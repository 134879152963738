<template>
<div class="page-reporte-lista-precios">
  <v-container>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </v-container>

  <v-container fluid fill-height>
    <v-row :justify="'center'" :align="'center'">
      <v-col sm='12'>
        <base-material-card color="primary" icon="library_books" title="Reporte de Compras por Articulo" class="elevation-1 px-5 py-3">

          <v-card-text>
            <v-form ref="form" lazy-validation>
              <v-container grid-list-md>
                <v-row>
                  <v-col sm="6" md="6" lg="6">
                    <v-autocomplete v-model="model.id_articulo" :items="articulos" :hide-no-data="true" :loading="isLoading" ref="selectarticulo" :search-input.sync="search_articulo" placeholder="Ingrese nombre de artículo para buscar"
                      prepend-icon="search" clearable item-text="nombre" item-value="_id" label="Artículo" :rules="[rules.required]">
                    </v-autocomplete>
                  </v-col>
                  <v-col sm="3" md="3" lg="3">
                      <v-menu offset-x ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" min-width="290px" offset-y>
                        <template v-slot:activator="{ on }">
                            <v-text-field v-on="on" v-model="model.fecha_de" label="Fecha De" color="secondary" prepend-icon="event"
                            @blur="model.fecha_de = parse_date(model.fecha_de)" :rules="[rules.required, valida_fecha]">
                            </v-text-field>
                        </template>

                        <v-date-picker v-model="model.fecha_de" color="secondary" scrollable locale="es-mx" @input="menu1 = false">
                            <v-spacer />

                            <v-btn color="secondary" x-small @click="menu1 = false">
                            Cancelar
                            </v-btn>
                        </v-date-picker>
                        </v-menu>
                  </v-col>
                  <v-col sm="3" md="3" lg="3">
                      <v-menu offset-x ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition" min-width="290px" offset-y>
                        <template v-slot:activator="{ on }">
                            <v-text-field v-on="on" v-model="model.fecha_a" label="Fecha A" color="secondary" prepend-icon="event"
                            @blur="model.fecha_a = parse_date(model.fecha_a)" :rules="[rules.required, valida_fecha]">
                            </v-text-field>
                        </template>

                        <v-date-picker v-model="model.fecha_a" color="secondary" scrollable locale="es-mx" @input="menu2 = false">
                            <v-spacer />

                            <v-btn color="secondary" x-small @click="menu2 = false">
                            Cancelar
                            </v-btn>
                        </v-date-picker>
                        </v-menu>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click.native="model = clean_model()">
              <v-icon>cancel</v-icon> Limpiar Filtros
            </v-btn>
            <v-btn color="success" @click.native="generar_reporte()">
              <v-icon>done</v-icon> {{ "Generar Reporte" }}
            </v-btn>
            <v-btn color="info" @click.native="consultar()">
              <v-icon>search</v-icon> Vista Previa
            </v-btn>
          </v-card-actions>

          <v-card-text>
            <v-client-table ref="vuetable" :data="items" :columns="columns" :options="options" class="elevation-2 pa-2">
                  <template slot="noResults">
                      <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                  </template>
                  <template slot="cantidad" slot-scope="props">
                      <div style="text-align:center !important;">
                          {{props.row.cantidad}}
                      </div>                    
                  </template>
                  <template slot="precio_sin_impuestos" slot-scope="props">
                      <div style="text-align:right !important;">
                          ${{formatNumberDec(props.row.precio_sin_impuestos,2)}}
                      </div>                    
                  </template>
                  <template slot="importe_sin_impuestos" slot-scope="props">
                      <div style="text-align:right !important;">
                          ${{formatNumberDec(props.row.importe_sin_impuestos,2)}}
                      </div>                    
                  </template>
                  <template slot="precio_con_impuestos" slot-scope="props">
                      <div style="text-align:right !important;">
                          ${{formatNumberDec(props.row.precio_con_impuestos,2)}}
                      </div>                    
                  </template>
                  <template slot="importe_con_impuestos" slot-scope="props">
                      <div style="text-align:right !important;">
                          ${{formatNumberDec(props.row.importe_con_impuestos,2)}}
                      </div>                    
                  </template>
                  
              </v-client-table>
          </v-card-text>


        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

</div>
</template>

<script>
export default {
  components: {},
  watch: {
    model_filters: {
      handler(val) {
        this.$nextTick(() => {
          this.items = [];
          this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        });
      },
      deep: true
    },
    search_articulo(val) {
      //Si no ha escrito 2 letras o mas
      if (val == null || val.length < 2) return
      // Si todavía esta buscando
      if (this.isLoading) return
      this.isLoading = true
      let data = {
        "selector": {
          "type": "articulos",
          "nombre": {
            "$regex": "(?i)" + val.toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")
          },
          "estatus": "Activo"
        },
      };

      window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
        .then(response => {
          if (response.data.docs.length > 0) {
            this.articulos = response.data.docs;
          } else
            this.articulos = [];
        })
        .catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener los articulos.",
            footer: ""
          });
        }).then(() => {
          this.isLoading = false;
        });
    },
    search_marca: {
      handler(val) {
        if (val == "to_update") return;
        if (val == "" || val == undefined) {
          return;
        };
        this.getMarcas(val);
      }
    },

  },
  mounted: function() {
    if (!this.verificaPermiso('t.compras.articulos')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
  },
  created: function() {
    this.model = this.clean_model();
    this.model.fecha_de = moment().format("YYYY-MM-DD");
    this.model.fecha_a = moment().format("YYYY-MM-DD");
  },
  data() {
    return {
      columns: [
          "no_orden",
          "fecha",
          "folio",
          "proveedor",
          "cantidad",
          "precio_sin_impuestos",
          "importe_sin_impuestos",
          "precio_con_impuestos",
          "importe_con_impuestos"
      ],
      options: {
        perPage: 10,
      pagination: {
        show: true
      },
          filterable: ["no_orden",
          "fecha",
          "folio",
          "proveedor",
          "cantidad",
          "precio_sin_impuestos",
          "importe_sin_impuestos",
          "precio_con_impuestos",
          "importe_con_impuestos"],
          sortable: ["no_orden",
          "fecha",
          "folio",
          "proveedor",
          "cantidad",
          "precio_sin_impuestos",
          "importe_sin_impuestos",
          "precio_con_impuestos",
          "importe_con_impuestos"],
          headings: {          
            "folio":"Folio Factura",                    
          "precio_sin_impuestos":"Precio S/Imp",
          "importe_sin_impuestos":"Importe S/Imp",
          "precio_con_impuestos":"Precio C/Imp",
          "importe_con_impuestos":"Importe C/Imp"
          },
      },
      menu1:false,
      menu2:false,
      sucursales: [],
      search_articulo: '',
      search_marca: null,
      categorias: [],
      marcas: [],
      articulos: [],
      articulos_proveedores: [],
      fab: [],
      isLoading: false,
      articulos_encontrados: [],
      id_articulo: "",

      search: "",
      model_filters: "",

      tmp_fecha: "",
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Reportes",
          disabled: true,
          href: ""
        },
        {
          text: "Compras por Articulo",
          disabled: true,
          href: ""
        }
      ],
      modal: false,
      modal_articulos: false,
      update: false,
      model: {},
      rules: {
        required: v => !!v || "Este campo es requerido",
        decimal: value => {
          const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
          return pattern.test(value) || "Número decimal no válido";
        },
        entero: function(numero) {
          const pattern = /^[\d]*$/;
          return pattern.test(numero) || "Debe ser un número entero";
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Correo electrónico no valido";
        },
        rfc: v =>
          (v && v.length >= 9 && v.length < 15) || "RFC debe tener al menos 9 caractéres y 14 caractéres"
      },
      items: []
    }
  },
  methods: {
    formatNumberDec: function(numero, decimales){
        if (numero == undefined || numero == null || numero == "")
            numero = 0;
        numero = Number(numero);
        if (isNaN(numero)){
            return "";
        } else
            return (numero).toFixed(decimales).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    },
    parse_date(date) {
      if (!date) return null;
      return moment(String(date)).format("YYYY-MM-DD");
    },
    valida_fecha: function(fecha) {
      if (fecha == "Invalid date")
        return "Fecha no válida."
      else
        return true;
    },
    clean_model: function() {
      return {
        id_articulo: '',
        fecha_de:'',
        fecha_a:''
      };
    },
    generar_reporte: function() {
      try {
        if(!this.$refs.form.validate()){return;}
        window.dialogLoader.show('Espere un momento por favor..');

        this.model.preview = 0;

        if (this.model.id_articulo == null)
          this.model.id_articulo = '';

        window.axios
            .post(process.env.VUE_APP_REPORTES_URL + "/reportes/rpt_compras_articulo/", this.model, {
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Allow": "POST",
            "cache-control": "no-cache",
            "responseType": "arraybuffer"
          })
          .then(response => {
            var bytes = response.data;
            let blob = new Blob([bytes], {
                type: response.headers["content-type"]
              }),
              url = window.URL.createObjectURL(blob);

            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = url;
            a.download = "Compras_por_Articulo.xlsx";
            a.click();
            a.parentNode.removeChild(a);
          })
          .catch(error => {
            console.log(error);
            this.$swal({
              type: "error",
              title: "¡Operación no Permitida!",
              text: "Ocurrió un error al generar el reporte. Intente nuevamente.",
              footer: ""
            });
          }).then(() => {
            window.dialogLoader.hide();
          });
      } catch (error) {
        console.log("ERRORS: ", error);
      }
    },
    consultar: function() {
      try {
        if(!this.$refs.form.validate()){return;}
        window.dialogLoader.show('Espere un momento por favor..');

        this.model.preview = 1;

        if (this.model.id_articulo == null)
          this.model.id_articulo = '';

        window.axios
          .post(process.env.VUE_APP_REPORTES_URL + "/reportes/rpt_compras_articulo/", this.model)
          .then(response => {
            this.items = response.data;            
          })
          .catch(error => {
            console.log(error);
            this.$swal({
              type: "error",
              title: "¡Operación no Permitida!",
              text: "Ocurrió un error al generar vista previa. Intente nuevamente.",
              footer: ""
            });
          }).then(() => {
            window.dialogLoader.hide();
          });
      } catch (error) {
        console.log("ERRORS: ", error);
      }
    },



  }
}
</script>
